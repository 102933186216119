<template>
  <main v-if="isShopify">
    <div v-if="!loading && renderComponent">
      <div class="page-wrapper-shopify is-full-screen">
        <!-- <div class="overlay" :class="{ isOpen: miniVariant }"></div> -->
        <!-- ======================== Order List Table ============================= -->
        <div class="container fluid">
          <div class="row">
            <PHeading element="h1">Your supplier account</PHeading>
            <PTextContainer>
              <p>
                Shopify uses this account to manage and sync with you VGANG
                Supplier account.
              </p>
            </PTextContainer>
            <v-row>
              <v-col class="d-flex align-center">
                <PAvatar customer name="Avatar-picture" />
                <p class="ma-0 pl-1">
                  {{ connectedSupplier.userEmail || "..." }}
                </p>
              </v-col>
              <v-col class="text-right">
                <PButton @click="DisconnectUser"> Disconnect </PButton>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div class="page-wrapper-shopify is-full-screen" v-else>
      <div class="container fluid">
        <PSkeletonPage fullWidth>
          <PLayout>
            <PLayoutSection>
              <PCard sectioned=""><PSkeletonBodyText /></PCard>
              <PCard sectioned="">
                <PTextContainer>
                  <PSkeletonDisplayText size="small" />
                  <PSkeletonBodyText />
                </PTextContainer>
              </PCard>
              <PCard sectioned="">
                <PTextContainer>
                  <PSkeletonDisplayText size="small" />
                  <PSkeletonBodyText />
                </PTextContainer>
              </PCard>
            </PLayoutSection>
            <!-- <PLayoutSection secondary="">
              <PCard>
                <PCardSection>
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText :lines="2" />
                  </PTextContainer>
                </PCardSection>
                <PCardSection><PSkeletonBodyText :lines="1" /></PCardSection>
              </PCard>
              <PCard subdued="">
                <PCardSection>
                  <PTextContainer>
                    <PSkeletonDisplayText size="small" />
                    <PSkeletonBodyText :lines="2" />
                  </PTextContainer>
                </PCardSection>
                <PCardSection><PSkeletonBodyText :lines="2" /></PCardSection>
              </PCard>
            </PLayoutSection> -->
          </PLayout>
        </PSkeletonPage>
      </div>
    </div>
  </main>
</template>
<script>
import headerNavigation from "@/views/shopify/components/headerNavigation.vue";
import CommonData from "@/services/CommonData";
// Services
import { removeVariable } from "@/utils/conditionalStoreVariables";
import AuthService from "@/services/AuthenticationService";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { headerNavigation },
  data() {
    return {
      loading: true,
      isShopify: true,
      userEmail: "...",

      renderComponent: true,
    };
  },
  async created() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
    }

    await this["supplier/getConnectedSupplier"]();
    await this["supplier/getSupplierInformation"]();
    if (this.supplierInfo) {
      this.loading = false;
    }
  },

  computed: {
    ...mapGetters({
      connectedSupplier: "supplier/supplierConnected",
      supplierInfo: "supplier/supplierInfo",
    }),
  },
  methods: {
    ...mapActions([
      "supplier/getConnectedSupplier",
      "supplier/getSupplierInformation",
      "supplier/updateSupplierInformation",
      "alert/clear",
    ]),
    async DisconnectUser() {
      try {
        const response = await AuthService.sellerUnAssignUser();
         
        if (response.status === 200) {
          const response = await AuthService.getInitialState();
          if (response.status === 200 && !response.data.userConnected) {
            this.$router.push("/supplier/login");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.np-footer {
  justify-content: flex-start;
  flex-wrap: wrap;
  .noFee {
    color: #008060;
  }
}
</style>
