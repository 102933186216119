<template>
  <div
    class="supplier-setting-page page-wrapper-shopify p-0"
    :class="{ 'is-full-screen': isShopify }"
  >
    <div class="container-fluid">
      <div class="row" v-if="true">
        <div class="col-sm-12 py-1">
          <h3 class="setting-header">Setting</h3>
        </div>
        <div class="col-sm-12 pb-0">
          <div class="forms-box">
            <div class="steps-box">
              <div class="body">
                <div
                  :class="step == 'GeneralInfo' ? 'active' : ''"
                  class="each-step"
                  @click="changeStep('GeneralInfo')"
                >
                  <span class="button-title"> General info </span>
                </div>

                <div
                  :class="step == 'ShippingInfo' ? 'active' : ''"
                  class="each-step"
                  @click="changeStep('ShippingInfo')"
                >
                  <span class="button-title"> Shipping info </span>
                </div>
                <div
                  :class="step == 'CatalogManagement' ? 'active' : ''"
                  class="each-step"
                  @click="changeStep('CatalogManagement')"
                >
                  <span class="button-title"> Catalog management </span>
                </div>
                <div
                  class="each-step"
                  :class="{
                    active: step == 'SyncingInventory',
                  }"
                  @click="changeStep('SyncingInventory')"
                >
                  <span
                    class="button-title"
                    :class="step == 'SyncingInventory' ? 'active' : ''"
                  >
                    Account
                  </span>
                </div>
              </div>
            </div>
            <div v-if="step == 'GeneralInfo'">
              <div class="body p-0">
                <GeneralInfo ref="GeneralInfo" @change-step="changeStep" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 py-0">
          <div class="forms-box">
            <div v-if="step == 'ShippingInfo'">
              <div class="body p-0">
                <ShippingInfo @change-step="changeStep" ref="ShippingInfo" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 py-0">
          <div class="forms-box">
            <div v-if="step == 'CatalogManagement'">
              <div class="body p-0">
                <CatalogManagement
                  @change-step="changeStep"
                  ref="CatalogManagement"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 py-0">
          <div class="forms-box">
            <div v-if="step == 'SyncingInventory'">
              <div class="body p-0">
                <Account @change-step="changeStep" ref="SyncingInventory" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-12">
          <div class="loading-wrapper">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomInput from "@/components/CustomInput.vue";
import GeneralInfo from "@/views/supplier/form-wizard/GeneralInformation.vue";
import ShippingInfo from "@/views/supplier/form-wizard/ShippingInfo.vue";
import CatalogManagement from "@/views/supplier/form-wizard/CatalogManagement.vue";
import Account from "./form-wizard/Account.vue";
export default {
  emits: ["change-step"],
  components: {
    CustomInput,
    GeneralInfo,
    ShippingInfo,
    CatalogManagement,
    Account,
  },
  data() {
    return {
      step: "GeneralInfo",
      isShopify: false,
      FinanceButtonIsDisabled: false,
      stepsValidations: {
        isValidShippingInfo: false,
      },
    };
  },
  mounted() {
    if (process.env.VUE_APP_SOURCE_NAME == "shopifyimporter") {
      this.isShopify = true;
    }
  },
  methods: {
    changeStep(step) {
      this.step = step;
    },
    async validateShippingInfo() {
      const isValid = await this.$refs.ShippingInfo.validate();
      if (isValid) {
        this.stepsValidations = isValid;
        this.step = "CatalogManagement";
      } else {
        this.stepsValidations = false;
      }
    },

    submitFinancialInformation(step) {
      const params = {
        discountPercentage: parseInt(this.discount.percentage),
        discountCode: this.discount.code,
      };
      RegisterationSetup.submitFinancialInformationStep(params, step)
        .then((response) => {
          this.step = response.data.step;
          if (this.step == "Complete") {
            this.$router.push("/supplier/assign-categoryV2");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
